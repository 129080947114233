<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Count of Tissue Evaluation</span>

      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
     
     
      <div id="chart"></div>

      
    </v-card-text>
  </v-card>
</template>

<script>

import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'
import daskboardserice from '@/service/Dashboard.service'
import moment from 'moment'
export default {

  setup() {
    const ins = getCurrentInstance()?.proxy
    const $vuetify = ins && ins.$vuetify ? ins.$vuetify : null
    const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'


    return {

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
   
   
   watch: {
    options: {
      handler() {
        this.chartMap()
      },
      deep: true,
    },
  },
  mounted() {
    this.chartMap()

  },
  methods:{

    async chartMap(){
      const service = new daskboardserice()
      let response = await service.getDaskboard()
      var data12 = response.weeklyevaluation
      const sectorCall = data12.map((d) => parseFloat(d["count"]))
      const sectordate = data12.map((d) => d["month"])
      //  console.log("nsdfdsf",sectordate)
     
      var options = {
      chart: {
        height: 450,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      colors:['#1bbc9b'],
      plotOptions: {
        bar: {
          columnWidth: '40%',
          distributed: true,
          borderRadius: 5,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      series: [
        {
          name: "Tissue Evaluation",
          type: "column",
          data: sectorCall
        }
      ],
      stroke: {
        width: [0, 4],
        curve: 'smooth'
      },
      labels: sectordate,
      xaxis: {},
      yaxis: []
    };

    var chart = new ApexCharts(document.querySelector("#chart"), options);

    chart.render();
    }
  }
}
</script>
